/*Global Defaults*/
html {
    height: -webkit-fill-available;
}

body {
    /* height: 100%; */
    margin: 0%;
    height: -webkit-fill-available;
    overscroll-behavior: none;
}
a {
    text-decoration: none; /* Removes the underline */
    color: inherit; /* Ensures the text color is inherited from the parent */
  }
button {
    display: inline-block;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    color:#000000;
    text-align: center;
    text-decoration: none;
    border: 2px solid #3f3f3f;
    border-radius: 4px;
    cursor: pointer;
  }
  button.classico {
    display: inline-block;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: black;
    background-color: buttonface;
    border: 2px solid black;
    border-radius: 4px;
    cursor: pointer;
  }
  button:hover:not(button:disabled) {
    color: rgb(255, 255, 255);
    background-color: #f0bd90;
    border: 2px solid fef7e863;
    transform: scale(1.05); /* Scale the button on hover */
  }
  
  button:focus {
    outline: none;
    color: #757575;
    border: 2px solid #757575;
  }
  button:disabled {
    outline: none;
    color: #0000002b;
    border: 2px solid #0000002b;
  }

.use-full-width {
    display: flex;
    justify-content: center;
    width: 100%;
}
.flex {
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
}

/*Important Components*/

.slide-container {
    /* height: 100vh; Set the height to 100% of the viewport height */
    width: 100%; /* Set the width to 100% of the parent container */

    display: flex;
    flex-direction: column;
    justify-content:center;
}
.view-fill {
    display: flex;
    height: 100vh;
    align-self: center;
}
.slider {
    display: flex;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
}
.slides {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 100%);
}
.slides .canvas, .slides .canvas-container {
    display: flex;
    flex-direction: column;
    /* position: sticky; */

    top: 0;
    height: 100vh;
    width: 100%;

    flex: 1 0 100%;
    scroll-snap-align: start;
    align-self: stretch;
}
.canvas {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.transf-up {
    transform: translate(0px, -3em);
}
.dvh-top-margin {
    margin-top: -3em;

}

.fifty-cent {
    width: 50%;
}

.flex-rows {
    display: flex;
    align-self: center;
    flex-direction: column;
    gap: 2em;
    background: linear-gradient(180deg, #fff5e5 0%, #8ea6a2 100%);
    border: 1em solid white;
    padding: 2em;
    z-index: 2;
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
}

.flex-rows span {
    box-sizing: border-box;
    color: black;
    background-color: #e6e6e6;
    border-radius: 4px;
    padding: 2em;
    border-radius: 0.67em;
    box-shadow: -0px 0px 20px 5px rgba(0, 0, 0, 0.1);
}

.canvas img {
    width: 80%;
}


/*Shorten Text-blcoks on mobile*/
.long-text {
    display: none;
}
.short-text {
    display: inline;
}

.exit-btn, button.classico {
    margin-top: 1em;
    display: none;
}

@media (min-width: 600px) {
    .slides .canvas, .slides .canvas-container {
        height: 100dvh;
    }
    .canvas img {
        width: 25em;
    }
    .long-text {
        display: inline;
    }
    .short-text {
        display: none;
    }
    .exit-btn, button.classico {
        display: inline-block;
    }
  }

.product-img {
    box-shadow: -0px 0px 20px 5px rgba(0, 0, 0, 0.1);
}

.page-ending-break {
    display: flex;
    align-items: flex-start;
    margin-bottom: 0.67em;
}
.page-ending-break hr {
    border: none;
    height: 2px;
    background-color: #000000;
    width: 100%;
    margin: 0;
    flex-grow: 1;
  }
.page-ending-break span {
    margin-left: 1.34em;
    margin-right: 1.34em;
    margin-top: -0.62em;
    flex-shrink: 0;

    font-family: auto;
    font-size: 24px;
    font-style: normal;
}

.footer {
    /* height: 8em; */
    /* background-color: black; */

/* color: white; */
/* font-weight: 700; */
line-height: normal;

text-align: center;
background-color: #fffaf4;
/* background-image: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0.8)),url(../assets/moneybg.jpg);
background-size: cover; */
}
.footer ul,
.nav ul, .select ul {
  /* CSS properties for both .footer list and .nav list */ 
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  width: 100%;
  list-style: none;

  font-family: Inter;
/* font-size: 30px; */
font-style: normal;
}

.nav-link:hover {
    color: #0072b0;
}


#footer.dim {
    color: #0000002b;
}

.checkout-button {
    text-decoration: none;
}
.button-margin {
    margin: 2.68em;
}

/*Lesser Components*/
.slide-heading {
    width: 100%;
    text-align: center;

    font-family: Inter;
    font-size: 2em;
    /* -webkit-text-stroke: 0.5px #3f3f3f63; */
    color: black;

    /* flex-shrink: 0; */
}
.row-3 p {
    font-size: 1.5em;
    width: 50%;
    text-align: center;
}
.canvas-child {
    align-self: center;
    /* flex-basis: 100%; */
}
.fill-line {
    display: flex;
    justify-content: center;
    width: 100%;
}

.faq-grid {
    display: grid;
    align-self: center;
    width: fit-content;
    min-width:20%;
    max-width:80%;
    font-size: 1.1em;
    background-color:#fffaf4;
    box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.5);
}

.faq-grid span {
    padding:1em;
}

.canvas h1 {
    align-self: center;
}

/*CANCELLATION PAGE STYLES*/        /*CANCELLATION PAGE STYLES*/

    /*CANCELLATION PAGE STYLES*/        /*CANCELLATION PAGE STYLES*/

/*CANCELLATION PAGE STYLES*/        /*CANCELLATION PAGE STYLES*/
.order-page 
{
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    flex: 1 0 100%;
    scroll-snap-align: start;
    align-self: center;
    justify-content: center;
    background-image: linear-gradient(rgba(0,0,0,0.8),rgba(0,0,0,0)),url(../assets/sandbg2.jpg);
    background-size:cover;
}
.wrapper {
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 2em;
    background-color: white;
    align-self: center;
}
.center {
    align-self: center;
}
.text-center {
    text-align: center;
}
.margin-none {
    margin: 0;
}
.marketing-form, .track-order-form {
    text-align: center;
}

.button-container {
    pointer-events: none;
}

.button-container button {
    pointer-events: auto;
}


.price-wrapper {
    text-align: center;
    /* margin-top: 2.5em; */
    color: #000000;
}
.price-wrapper h2, .price-wrapper h3, .price-wrapper h1 {
    margin: 0;
}
.price-wrapper h1 {
    font-size: 2.2em;
}
.price-wrapper h2 {
    font-weight:normal;
}
.price-wrapper h1:hover {
    transform: scale(1.2);
    color: #f4decc;
}