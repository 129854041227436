#dropdown-menu {
    min-width: 15em;
        position: sticky;
        top: 0; /* Position the navbar at the top of the scrolling area */
        z-index: 1; /* Ensure the navbar appears above other elements */
        /* Additional styles for the navbar */
    color: #000000;
    font-family: Inter;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;

    }
    #dropdown-menu .page-title {
        margin-left: 0.25em;
        transition: color 250ms 50ms;
        color: #000000;
        text-decoration: none;
        display: inline-block;
    }
    #dropdown-menu .page-title:hover:not(#dropdown-menu.dim .page-title:hover) {
        color: #0072b0;
        text-shadow: -1px 0px 1px rgba(0, 0, 0, 0.2);
        transform: scale(1.05);
    }
    #dropdown-menu svg:hover rect {
        fill: #0072b0;
        filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.1));
    }
    #dropdown-menu svg:hover {
        transform: scale(1.1);
    }
    #dropdown-menu.dim .nav-wrapper {
        background-color: #00000000;
        transition: background-color 250ms 50ms;
    }
    #dropdown-menu #dropdown-icon {
        transition: transform 100ms ;
    }
    /* #dropdown-menu.dim {
        color: #0000002b;
    } */
    #dropdown-menu.dim .page-title {
        /* display: none; */
        color: #00000000;
        visibility: hidden;
        transition: color 150ms ;
    }
    #dropdown-menu.dim #dropdown-icon {
        transform: translateX(-235px);
        transition: transform 250ms ;
        z-index: 2;
    }
    #dropdown-menu:hover {
        cursor: pointer;
    }
 #dropdown-menu *:not(.nav-wrapper):not(.page-title) {
    box-sizing: border-box;

    font-family: Helvetica, sans-serif;
    font-size: 1rem;
 }

 #dropdown-menu.light .page-title,  #dropdown-menu.light .nav-wrapper {
    color: white;
    background-color: transparent;
 }
 #dropdown-menu.light {
    position: absolute;
 }
 #dropdown-menu.light .nav-wrapper {
    cursor: auto;
 }
 #dropdown-menu.light svg {
    display: none;
 }
 .nav-wrapper {
    display: inline-block;
    background-color: #f9f5f4;
    width: 100%;
 }

 .page-title.hidden {
    display: none;
 }

 .select {
    background: #2a2f3b;
    color: #fff;
    font-weight: 700;
    justify-content: space-between;
    align-items: center;
    display: flex;
    position: relative;
    z-index: 0;
 }
 .select.toggled {
    display: none;
 }
 .select ul {
    display: flex;
    padding-left: 1em;
    gap: 1em;
    align-items: center;
 }
 .right {
    margin-left: auto;
    margin-right: 0.5em;
    font-weight: normal;
}
.nav-disclaimer {
    color: gray;
    margin-left: 1.5em;
    font-size: .85rem;
}