@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap');
.countdown {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    display: flex;
    justify-content: center;
    gap: 2em;
    text-align: center;
    margin: 1em;
}

.time {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.time h2 {
    /* font-weight: bold; */
    font-size: 36px;
    line-height: 1;
    margin: 0 0 5px;
}

#suffix {
    font-family: "Poppins", sans-serif;
}