.slides {    
    transition: background-color 250ms ease;
    background: linear-gradient(180deg, rgb(254 247 230) 0%, rgb(255, 237, 218) 100%);
}
#slide2 {
    grid-column-start: 2;
}

#slide1-row1 {
    height: 100dvh;
    background-image: linear-gradient(rgba(255, 255, 255, 0.8),rgba(255, 255, 255, 0.0)),url(../assets/sandbg.jpg);
    background-size: cover;
}

#slide1-row2 {
    display: none;;
}

#slide1-row3 {
    display: none;;
}
#slide1-row4 {
    flex-wrap:nowrap;
    height: auto;
    padding-bottom: 4em;
}

.background1 {
    background: none;
}

.background2 {
    opacity: 1;
    transition: background-color 250ms;
    /* background-color: rgb(203, 229, 252,0.8); */
    /* background: linear-gradient(180deg, rgb(254 247 230) 0%, rgb(164, 115, 63) 100%); */


}

.background3 {
    background-color: rgb(220, 252, 203,0.8);
}

.background4 {
    background-color: rgba(255, 217, 0, 0.801);
}